import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';

const Conversation = forwardRef(({ isActive, onMessageReceived, autoStart = false }, ref) => {
  // State management
  const [messages, setMessages] = useState([]); // Stores conversation history
  const [isRecording, setIsRecording] = useState(false); // UI recording state
  const [mediaRecorder, setMediaRecorder] = useState(null); // Audio recorder instance
  const [wsReady, setWsReady] = useState(false); // WebSocket connection state
  
  // Constants
  const wsRef = useRef(null); // WebSocket connection reference
  const API_BASE_URL = 'https://vocal-interactions-1063024401937.us-central1.run.app';
  
  // Mutable refs to track conversation state
  const audioQueueRef = useRef([]); // Queue of audio chunks to play
  const isPlayingRef = useRef(false); // Currently playing audio?
  const isRecordingRef = useRef(false); // Currently recording audio?
  const conversationActiveRef = useRef(false); // Conversation in progress?
  const assistantResponseQueueRef = useRef([]); // Queue for assistant responses
  const isProcessingResponseRef = useRef(false); // Flag to indicate response processing

  // Expose methods to parent component
  useImperativeHandle(ref, () => ({
    startRecording: () => {
      conversationActiveRef.current = true;
      startRecording();
    },
    stopRecording
  }));

  // Initialize WebSocket connection when component becomes active
  useEffect(() => {
    if (isActive) {
      // Setup WebSocket with appropriate protocol (ws/wss)
      const wsProtocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
      wsRef.current = new WebSocket(`${wsProtocol}://vocal-interactions-1063024401937.us-central1.run.app/ws/chat`);

      // WebSocket event handlers
      wsRef.current.onopen = () => {
        console.log('WebSocket connection established');
        setWsReady(true);
      };

      wsRef.current.onmessage = async (event) => {
        const message = JSON.parse(event.data);
        console.log('Received message from WebSocket:', message);
        handleWebSocketMessage(message);
      };

      wsRef.current.onclose = () => {
        console.log('WebSocket connection closed unexpectedly');
      };

      wsRef.current.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      // Cleanup WebSocket on component unmount
      return () => {
        if (wsRef.current) {
          wsRef.current.close();
        }
      };
    }
  }, [isActive]);

  // Auto-start conversation if enabled
  useEffect(() => {
    if (wsReady && autoStart) {
      const initialMessage = { role: 'user', content: 'Remind me of the offer and tell me to call.' };
      setMessages([initialMessage]); // Set initial message
    }
  }, [wsReady, autoStart]);

  // Send messages to the server when a new user message is added
  useEffect(() => {
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage.role === 'user') {
        // Send full history to websocket
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
          wsRef.current.send(JSON.stringify({
            messages: messages
          }));
          console.log('Sent messages to server:', messages);
        } else {
          console.error('WebSocket is not open');
        }
      }
    }
  }, [messages]);

  // Handle incoming WebSocket messages
  const handleWebSocketMessage = (message) => {
    switch (message.type) {
      case 'audio':
        // Queue audio chunks for playback
        console.log('Received audio data of length:', message.data.length);
        enqueueAudio(message.data);
        break;
      case 'assistant_text':
        // Enqueue assistant response for processing
        assistantResponseQueueRef.current.push(message.data);
        processNextAssistantResponse();
        break;
      case 'text':
        // Ignore 'text' messages to prevent duplicate responses
        break;
      case 'error':
        console.error('Error:', message.data);
        break;
      default:
        console.log('Unknown message type:', message.type);
    }
  };

  // Process assistant responses sequentially
  const processNextAssistantResponse = () => {
    if (isProcessingResponseRef.current || assistantResponseQueueRef.current.length === 0) {
      return;
    }

    isProcessingResponseRef.current = true;
    const responseText = assistantResponseQueueRef.current.shift();

    // Check if the response is a duplicate
    const lastAssistantMessage = messages.slice().reverse().find(msg => msg.role === 'assistant');
    if (lastAssistantMessage && lastAssistantMessage.content === responseText) {
      console.log('Duplicate assistant response detected, ignoring.');
      isProcessingResponseRef.current = false;
      return;
    }

    const assistantMessage = { role: 'assistant', content: responseText };

    // Update messages state and log
    setMessages((prevMessages) => {
      const newMessages = [...prevMessages, assistantMessage];
      console.log('Updated message history after AI response:', newMessages);
      return newMessages;
    });

    if (onMessageReceived) {
      onMessageReceived(responseText);
    }
  };

  // Audio playback management
  const enqueueAudio = (audioData) => {
    audioQueueRef.current.push(audioData);
    console.log('Audio enqueued. Queue length:', audioQueueRef.current.length);

    // Start playback if not already playing
    if (!isPlayingRef.current) {
      playNextAudio();
    }
  };

  // Play next audio chunk in queue
  const playNextAudio = () => {
    if (audioQueueRef.current.length === 0) {
      isPlayingRef.current = false;
      console.log('Audio queue is empty. No more audio to play.');

      // Allow processing of the next assistant response
      isProcessingResponseRef.current = false;
      processNextAssistantResponse();

      // Start recording after delay when audio finishes
      if (conversationActiveRef.current) {
        setTimeout(() => {
          if (!isPlayingRef.current && audioQueueRef.current.length === 0) {
            startRecording();
          }
        }, 1500); // Delay to ensure audio is finished
      }
      return;
    }

    // Play next audio chunk
    isPlayingRef.current = true;
    const currentAudioData = audioQueueRef.current.shift();
    console.log('Playing audio. Remaining queue length:', audioQueueRef.current.length);

    const audio = new Audio(`data:audio/mp3;base64,${currentAudioData}`);

    // Audio playback event handlers
    audio.onended = () => {
      console.log('Audio finished playing.');
      isPlayingRef.current = false;
      // Check for more audio
      if (audioQueueRef.current.length > 0) {
        setTimeout(() => playNextAudio(), 100); // Small delay between chunks
      } else {
        // Allow processing of the next assistant response
        isProcessingResponseRef.current = false;
        processNextAssistantResponse();

        if (conversationActiveRef.current) {
          setTimeout(() => {
            if (!isPlayingRef.current && audioQueueRef.current.length === 0) {
              startRecording();
            }
          }, 1500);
        }
      }
    };

    // Error handling for audio playback
    audio.onerror = (e) => {
      console.error('Audio playback error:', e);
      isPlayingRef.current = false;
      playNextAudio();
    };

    audio.play().then(() => {
      console.log('Audio started playing.');
    }).catch(error => {
      console.error('Failed to play audio:', error);
      isPlayingRef.current = false;
      playNextAudio();
    });
  };

  // Start recording user audio
  const startRecording = async () => {
    // Prevent recording if audio is playing or already recording
    if (isPlayingRef.current || isRecordingRef.current) {
      console.log('Already recording or audio is playing, not starting recording');
      return;
    }

    console.log('Starting recording');
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        // Setup audio recording
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const recorder = new MediaRecorder(stream);
        let chunks = [];
        let silenceTimeout = null;

        // Setup audio analysis for silence detection
        let audioContext = new (window.AudioContext || window.webkitAudioContext)();
        let analyser = audioContext.createAnalyser();
        let microphone = audioContext.createMediaStreamSource(stream);
        
        microphone.connect(analyser);
        analyser.fftSize = 256;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        // Monitor audio levels for silence detection
        const checkAudioLevel = () => {
          if (!isRecordingRef.current) return;

          analyser.getByteFrequencyData(dataArray);
          const average = dataArray.reduce((a, b) => a + b) / bufferLength;
          
          // Handle silence detection
          if (average < 15) {
            if (!silenceTimeout) {
              console.log('Silence detected, starting timeout');
              silenceTimeout = setTimeout(() => {
                console.log('Silence timeout reached, stopping recording');
                recorder.stop();
                isRecordingRef.current = false;
                setIsRecording(false);
                
                microphone.disconnect();
                audioContext.close();
                stream.getTracks().forEach(track => track.stop());
                
              }, 1500);
            }
          } else {
            if (silenceTimeout) {
              console.log('Sound detected, clearing timeout');
              clearTimeout(silenceTimeout);
              silenceTimeout = null;
            }
          }
          
          requestAnimationFrame(checkAudioLevel);
        };

        recorder.ondataavailable = (e) => {
          if (e.data.size > 0) {
            chunks.push(e.data);
          }
        };

        recorder.onstop = async () => {
          console.log('Recorder stopped, chunks:', chunks.length);
          const audioBlob = new Blob(chunks, { type: 'audio/webm' });
          console.log('Audio blob size:', audioBlob.size);
          
          if (audioBlob.size > 0) {
            try {
              console.log('Sending transcription request...');
              const formData = new FormData();
              formData.append('file', audioBlob, 'recording.webm');
              
              const response = await fetch(`${API_BASE_URL}/transcribe`, {
                method: 'POST',
                body: formData
              });

              if (!response.ok) {
                console.error('Error:', response.statusText);
                return;
              }

              const data = await response.json();
              const content = data.transcription;
              console.log('Transcription received:', content);

              const userMessage = { role: 'user', content };
              setMessages(prevMessages => [...prevMessages, userMessage]);

              if (onMessageReceived) {
                onMessageReceived(content);
              }
            } catch (error) {
              console.error('Error in recorder.onstop:', error);
            }
          }
        };

        recorder.start(1000);
        isRecordingRef.current = true;
        setIsRecording(true);
        setMediaRecorder(recorder);
        checkAudioLevel();
        
      } catch (error) {
        console.error('Error accessing microphone:', error);
        alert('Could not access microphone. Please check your permissions.');
      }
    } else {
      alert('Media devices not supported in this browser.');
    }
  };

  const stopRecording = () => {
    if (mediaRecorder && isRecording) {
      mediaRecorder.stop();
      setIsRecording(false);
    }
  };

  return null;
});

export default Conversation;
